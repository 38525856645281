var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-incidence","hide-footer":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h4',[_vm._v(" "+_vm._s(_vm.$t("nursing.add-incidence"))+" ")])]},proxy:true}])},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"new-user-info"},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"mb":"12"}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":_vm.$t('nursing.description'),"label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('nursing.description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-textarea',{class:'form-control mb-0' +
                      (errors.length > 0 ? ' is-invalid' : ''),attrs:{"id":"textarea","placeholder":_vm.$t('nursing.description-placeholder'),"rows":"3","max-rows":"6"},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)],1),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":_vm.$t('nursing.image'),"label-for":"title"}},[_c('div',{staticClass:"text-center mb-4",staticStyle:{"cursor":"pointer"}},[_c('b-img',{staticClass:"mt-2",staticStyle:{"max-width":"300px"},attrs:{"id":"pick-image","src":_vm.preview
                    ? _vm.preview
                    : require('@/assets/images/logo/placeholder.png')}}),_c('br'),_c('small',[_vm._v("Click para agregar imagen")]),_c('avatar-cropper',{attrs:{"upload-handler":_vm.selectedFile,"trigger":"#pick-image","labels":{
                  submit: _vm.$t('app.accept'),
                  cancel: _vm.$t('app.cancel'),
                },"cropper-options":{
                  aspectRatio: 0,
                  autoCropArea: 1,
                  viewMode: 1,
                  movable: true,
                  zoomable: true,
                }},on:{"changed":_vm.setFileName}})],1)])],1),_c('hr'),_c('b-button',{staticClass:"float-right",attrs:{"disabled":_vm.nursingLoading,"variant":"success","type":"submit"}},[(_vm.nursingLoading)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t("app.loading"))+"... ")],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t("app.save"))+" ")])]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"variant":"outline-danger","type":"reset"},on:{"click":function($event){return _vm.$bvModal.hide('modal-incidence')}}},[_vm._v(" "+_vm._s(_vm.$t("app.cancel"))+" ")])],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }