<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("nursing.index") }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$refs.modalNursing.show()">{{
              $t("nursing.new")
            }}</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12" md="1" class="pr-1 mb-2">
                <b-form-select
                  :options="['5', '10', '20', '50', '100']"
                  v-model="filter.per_page"
                  size="sm"
                  @input="getData()"
                ></b-form-select>
              </b-col>
              <b-col sm="12" md="3" class="ml-auto mb-2">
                <b-form-input
                  style="height: 35px"
                  v-model="filter.search"
                  size="sm"
                  type="search"
                  :placeholder="$t('app.search')"
                ></b-form-input>
              </b-col>

              <b-col md="12" class="table-responsive">
                <b-table
                  class="table mb-0 table-borderless"
                  :items="nursingReports.data"
                  :fields="columns"
                  :per-page="nursingReports.per_page"
                  :busy="
                    !Array.isArray(nursingReports.data) ||
                    nursingReports.data.length === 0
                      ? true
                      : false || nursingLoading == true
                  "
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="nursingLoading">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> {{ $t("app.loading") }}...</strong>
                      </div>
                      <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      @click="showModalEdit(data.item)"
                      variant=" iq-bg-warning mr-1 rounded"
                      size="sm"
                      ><i class="ri-ball-pen-fill ml-1"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-danger rounded"
                      size="sm"
                      @click="deleteReport(data.item.id)"
                      ><i class="ri-delete-bin-line ml-1 rounded"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-primary rounded"
                      class="mx-1"
                      size="sm"
                      @click="showCurrentReport(data.item)"
                      ><i class="ri-eye-fill ml-1"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-if="nursingReports.total > 0"
                  class="mt-3 float-right"
                  v-model="filter.page"
                  :total-rows="nursingReports.total"
                  :per-page="nursingReports.per_page"
                  @change="getData"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
                <p class="mt-3">
                  {{ $t("app.registers") }} {{ nursingReports.to }}
                  {{ $t("app.of") }} {{ nursingReports.total }}. Total
                  {{ nursingReports.total }}
                </p>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modal-form ref="modalNursing" @refresh="getData()" />

    <modal-form-record ref="modalNursingRecord" />

    <b-modal
      size="xl"
      hide-footer
      scrollable
      body-bg-variant="light"
      id="bv-modal-show-report"
      :title="$t('nursing.show')"
    >
      <section v-if="currentNursingReport">
        <b-row>
          <b-col md="12" sm="12">
            <h6>{{ $t("nursing.title") }}</h6>
            <p class="mt-1">
              {{ currentNursingReport.title }}
            </p>
            <h6>{{ $t("nursing.date") }}</h6>
            <p class="mt-1">
              {{
                moment(currentNursingReport.created_at).format(
                  "DD-MM-YYYY HH:mm [hrs]"
                )
              }}
            </p>
            <h6>{{ $t("nursing.patient") }}</h6>
            <p class="mt-1">
              {{
                `${currentNursingReport?.model?.user?.name} ${
                  currentNursingReport.model?.user?.first_lastname ?? ""
                }`
              }}
            </p>
            <h6>{{ $t("nursing.patient-rut") }}</h6>
            <p class="mt-1">
              {{ `${currentNursingReport?.model?.user?.rut}` }}
            </p>
            <div class="d-flex justify-content-between align-items-center my-2">
              <h6 class="">Historial</h6>
              <b-button
                @click="$refs.modalNursingRecord.show(currentNursingReport.id)"
                variant="primary"
                >{{ $t("nursing.add-incidence") }}</b-button
              >
            </div>
            <div
              class="d-flex justify-content-center align-items-center my-2"
              v-if="nursingLoading"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                label="Large Spinner"
              ></b-spinner>
            </div>

            <section v-if="!nursingLoading">
              <iq-card
                class="my-2 w-50 p-3 mx-auto"
                v-for="record in currentNursingReport.records"
                :key="record.id"
              >
                <i
                  v-b-tooltip.hover
                  @click="deleteReportRecord(record.id)"
                  :title="$t('nursing.delete-incidence')"
                  style="cursor: pointer"
                  class="ri-delete-bin-line text-danger float-right"
                ></i>

                <span class="pb-5">
                  {{ record.description }}
                </span>
                <br />
                <small>
                  {{
                    moment(record.created_at).format("DD-MM-YYYY HH:mm [hrs]")
                  }}
                </small>
                <br />
                <div>
                  <b-img-lazy
                    v-if="record.image"
                    :src="record.image"
                    width="180"
                    height="180"
                    fluid
                    class="mt-2"
                    alt="Record image"
                  >
                  </b-img-lazy>
                </div>
              </iq-card>
            </section>
            <p
              class="text-center mt-3"
              v-if="!currentNursingReport.records.length && !nursingLoading"
            >
              {{ $t("app.not-found") }}
            </p>
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./components/CreateOrUpdate.vue";
import modalFormRecord from "./components/CreateOrUpdateIncidence.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
export default {
  components: {
    modalForm,
    modalFormRecord,
  },
  mounted() {
    core.index();
    this.debouncedGetNursingReports = _.debounce(this.getData, 500);
    this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetNursingReports();
      },
    },
  },
  data() {
    return {
      moment,
      filter: {
        all: false,
        per_page: 10,
        search: null,
        page: 1,
      },
    };
  },
  methods: {
    ...mapActions([
      "fetchNursingReports",
      "deleteNursingReport",
      "deleteNursingReportRecord",
      "getNursingReport",
    ]),
    getData(page) {
      this.filter.page = page;
      this.fetchNursingReports(this.filter);
    },
    showModalEdit(data) {
      this.$refs.modalNursing.show(data);
    },
    async showCurrentReport(report) {
      await this.getNursingReport(report.id);
      this.$bvModal.show("bv-modal-show-report");
    },
    deleteReport(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteNursingReport(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
    deleteReportRecord(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteNursingReportRecord(id);
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.filter.page = 1;
    },
  },
  computed: {
    ...mapGetters({
      currentNursingReport: "nursingReport",
      nursingReports: "nursingReports",
      nursingLoading: "nursingLoading",
    }),
    columns() {
      return [
        { label: this.$t("nursing.title"), key: "title", class: "text-left" },
        {
          label: this.$t("nursing.patient"),
          key: "model",
          class: "text-left",
          formatter: (value, key, item) => {
            return `${item?.model?.user?.name} ${
              item.model?.user?.first_lastname ?? ""
            }`;
          },
        },
        {
          label: this.$t("nursing.patient-rut"),
          key: "model.user",
          class: "text-left",
          formatter: (value, key, item) => `${item.model.user.rut}`,
        },
        {
          label: this.$t("nursing.date"),
          key: "created_at",
          class: "text-left",
          formatter: (value) => `${moment(value).format("DD/MM/YYYY")}`,
        },
        {
          label: this.$t("nursing.author"),
          key: "author",
          class: "text-left",
          formatter: (value, key, item) =>
            `${item.author.name} ${item.author.first_lastname ?? ""}`,
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
