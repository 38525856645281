<template>
  <b-modal id="modal-nursing" hide-footer size="lg">
    <template #modal-header>
      <h4>
        {{ data.id === null ? $t("nursing.new") : $t("nursing.edit") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row align-h="between">
            <b-col mb="12">
              <b-row align-v="center">
                <!-- TITLE -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('nursing.title')"
                  label-for="title"
                >
                  <ValidationProvider
                    :name="$t('nursing.title')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="data.title"
                      type="text"
                      :placeholder="$t('nursing.title-placeholder')"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  label="Rol"
                  label-for="education"
                >
                  <ValidationProvider
                    name="Rol"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="roleSelected"
                      placeholder="Rol"
                      :options="roleCollege"
                      label="name"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #no-options="{}">
                        {{ $t("app.not-found") }}...
                      </template>
                    </v-select>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  v-if="roleSelected && roleSelected.name === ROLE_STUDENT"
                  class="col-md-12"
                  :label="$t('courses.course')"
                  label-for="education"
                >
                  <ValidationProvider
                    :name="$t('courses.course')"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="courseSelected"
                      :placeholder="$t('courses.course')"
                      :options="getCourses"
                      label="name"
                    >
                      <template
                        #selected-option="{ grade, name, schoolPeriod }"
                      >
                        <div>
                          {{ `${grade.name} - ${name}` }}
                        </div>
                        <br />
                        <cite>{{ schoolPeriod.name }}</cite>
                      </template>
                      <template #option="{ grade, name, schoolPeriod }">
                        <div>
                          {{ `${grade.name} - ${name}` }}
                          <br />
                          <cite>{{ schoolPeriod.name }}</cite>
                        </div>
                      </template>
                      <template #no-options="{}">
                        {{ $t("app.not-found") }}...
                      </template>
                    </v-select>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  :label="$t('nursing.patient')"
                  label-for="patient"
                >
                  <ValidationProvider
                    :name="$t('nursing.patient')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="data.user_id"
                      :placeholder="$t('nursing.patient')"
                      :options="wrappedUsers"
                      :disabled="!roleSelected"
                      label="name"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #option="{ name, first_lastname, email }">
                        <p style="margin: 0">
                          {{ `${name} ${first_lastname}` }}
                        </p>
                        <small>{{ email }}</small>
                      </template>
                      <template
                        #selected-option="{ name, first_lastname, email }"
                      >
                        <p style="margin: 0">
                          {{ `${name} ${first_lastname}` }}
                        </p>
                      </template>
                      <template #no-options>
                        {{ $t("app.not-found") }}...
                      </template>
                    </v-select>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <!-- DESCRIPTION -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('nursing.description')"
                  label-for="description"
                  v-if="!data.id"
                >
                  <ValidationProvider
                    :name="$t('nursing.description')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-textarea
                      id="textarea"
                      v-model="data.description"
                      :placeholder="$t('nursing.description-placeholder')"
                      rows="3"
                      max-rows="6"
                      :class="
                        'form-control mb-0' +
                        (errors.length > 0 ? ' is-invalid' : '')
                      "
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
            <b-form-group
              class="col-md-12"
              :label="$t('nursing.image')"
              label-for="title"
              v-if="!data.id"
            >
              <div class="text-center mb-4" style="cursor: pointer">
                <b-img
                  class="mt-2"
                  id="pick-image"
                  style="max-width: 300px"
                  :src="
                    preview
                      ? preview
                      : require('@/assets/images/logo/placeholder.png')
                  "
                ></b-img>
                <br />
                <small>Click para agregar imagen</small>

                <avatar-cropper
                  :upload-handler="selectedFile"
                  @changed="setFileName"
                  trigger="#pick-image"
                  :labels="{
                    submit: $t('app.accept'),
                    cancel: $t('app.cancel'),
                  }"
                  :cropper-options="{
                    aspectRatio: 0,
                    autoCropArea: 1,
                    viewMode: 1,
                    movable: true,
                    zoomable: true,
                  }"
                />
              </div>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            class="float-right"
            :disabled="nursingLoading"
            variant="success"
            type="submit"
          >
            <span v-if="nursingLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-nursing')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import AvatarCropper from "vue-avatar-cropper";
import { ROLE_STUDENT } from "@/constants/roles";
export default {
  name: "CreateOrUpdate",
  components: {
    AvatarCropper,
  },
  mounted() {
    core.index();
    this.fetchRolesCollege();
    this.fetchUserCollege();
    this.fetchCourses();
  },
  data() {
    return {
      fileSelected: null,
      preview: "",
      roleSelected: null,
      courseSelected: null,
      data: {
        id: null,
        title: null,
        description: null,
        user_id: null,
        image: null,
      },
      ROLE_STUDENT,
    };
  },
  methods: {
    ...mapActions({
      saveForm: "createOrUpdateNursingReport",
      fetchRolesCollege: "fetchRolesCollage",
      fetchUserCollege: "fetchUserCollege",
      fetchCourses: "fetchCourses",
      showCourses: "showCourses",
    }),
    async show(item) {
      this.reserForm();
      if (item) {
        this.preview = item.image;
        this.data = {
          id: item.id,
          title: item.title,
          description: item.description,
          user_id: item.model.user ? item.model.user : null,
        };
      }
      this.$bvModal.show("modal-nursing");
    },
    setFileName(file) {
      this.fileSelected = file;
      this.data.image = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.data.image = file;
    },
    async onSubmit() {
      const resp = await this.saveForm(this.data);
      if (resp?.status === 201 || resp?.status === 200) {
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("nursing.success-created")
            : this.$t("nursing.success-edit")
        );
        this.$bvModal.hide("modal-nursing");
        this.$emit("refresh");
      }
      this.reserForm();
    },
    reserForm() {
      this.preview = null;
      this.fileSelected = null;

      this.data = {
        id: null,
        title: null,
        description: null,
        image: null,
        user_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      getCourses: "getCourses",
      usersCollege: "getUserCollege",
      getStudentsCourses: "getStudentsCourses",
      roleCollege: "roleCollege",
      nursingLoading: "nursingLoading",
    }),
    usersCollegeByRol() {
      if (
        this.roleSelected &&
        this.roleSelected.name === ROLE_STUDENT &&
        this.courseSelected
      ) {
        return this.getStudentsCourses;
      }
      return this.usersCollege;
    },
    wrappedUsers() {
      return this.usersCollegeByRol
        .map((item) => {
          return {
            ...item.user,
            roles: item.roles,
          };
        })
        .filter((collegeUser) => {
          if (this.roleSelected && !this.courseSelected) {
            return collegeUser.roles.some(
              (rol) => rol.id === this.roleSelected.id
            );
          }
          return collegeUser;
        });
    },
  },
  watch: {
    roleSelected: {
      handler() {
        this.data.user_id = null;
        this.courseSelected = null;
      },
    },
    courseSelected: {
      handler(course) {
        if (course) {
          this.showCourses(course.id);
        }
      },
    },
  },
};
</script>
