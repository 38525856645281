<template>
  <b-modal id="modal-incidence" hide-footer size="lg">
    <template #modal-header>
      <h4>
        {{ $t("nursing.add-incidence") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row align-h="between">
            <b-col mb="12">
              <b-row align-v="center">
                <!-- DESCRIPTION -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('nursing.description')"
                  label-for="description"
                >
                  <ValidationProvider
                    :name="$t('nursing.description')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-textarea
                      id="textarea"
                      v-model="data.description"
                      :placeholder="$t('nursing.description-placeholder')"
                      rows="3"
                      max-rows="6"
                      :class="
                        'form-control mb-0' +
                        (errors.length > 0 ? ' is-invalid' : '')
                      "
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
            <b-form-group
              class="col-md-12"
              :label="$t('nursing.image')"
              label-for="title"
            >
              <div class="text-center mb-4" style="cursor: pointer">
                <b-img
                  class="mt-2"
                  id="pick-image"
                  style="max-width: 300px"
                  :src="
                    preview
                      ? preview
                      : require('@/assets/images/logo/placeholder.png')
                  "
                ></b-img>
                <br />
                <small>Click para agregar imagen</small>

                <avatar-cropper
                  :upload-handler="selectedFile"
                  @changed="setFileName"
                  trigger="#pick-image"
                  :labels="{
                    submit: $t('app.accept'),
                    cancel: $t('app.cancel'),
                  }"
                  :cropper-options="{
                    aspectRatio: 0,
                    autoCropArea: 1,
                    viewMode: 1,
                    movable: true,
                    zoomable: true,
                  }"
                />
              </div>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            class="float-right"
            :disabled="nursingLoading"
            variant="success"
            type="submit"
          >
            <span v-if="nursingLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-incidence')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import AvatarCropper from "vue-avatar-cropper";

export default {
  name: "CreateOrUpdateIncidence",
  components: {
    AvatarCropper,
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      fileSelected: null,
      preview: "",
      data: {
        nursing_report_id: null,
        description: null,
        image: null,
      },
    };
  },
  methods: {
    ...mapActions({
      saveForm: "createNursingReportRecord",
    }),
    async show(nursing_report_id) {
      this.reserForm();
      this.data.nursing_report_id = nursing_report_id
      this.$bvModal.show("modal-incidence");
    },
    setFileName(file) {
      this.fileSelected = file;
      this.data.image = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.data.image = file;
    },
    async onSubmit() {
      const resp = await this.saveForm(this.data);
      if (resp?.status === 201 || resp?.status === 200) {
        core.showSnackbar(
          "success",
          this.$t("nursing.success-incidence-created")
        );
        this.$bvModal.hide("modal-incidence");
      }
      this.reserForm();
    },
    reserForm() {
      this.preview = null;
      this.fileSelected = null;

      this.data = {
        nursing_report_id: null,
        description: null,
        image: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      nursingLoading: "nursingLoading",
    }),
  },
};
</script>
